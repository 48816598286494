//
// Sidebar
// --------------------------------------------------


.cz-sidebar,
.cz-sidebar-static {
  width: 100%;
  max-width: $sidebar-max-width;
  background-color: $sidebar-bg;
  .close { font-size: $h3-font-size; }
}
.cz-sidebar-static {
  padding: $sidebar-padding-y $sidebar-padding-x;
  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: {
      right: $sidebar-padding-x / 1.5;
      left: $sidebar-padding-x / 1.5;
    }
  }
}
.cz-sidebar-header {
  display: none;
  position: relative;
  width: 100%;
  height: $sidebar-header-height;
  padding: .25rem ($spacer * 1.5);
  background-color: $sidebar-bg;
}
.cz-sidebar-body {
  padding: $sidebar-padding-y $sidebar-padding-x;
  .simplebar-content {
    padding-right: $sidebar-padding-x !important;
  }
  & > .simplebar-track { display: none; }
  [data-simplebar] .simplebar-content > * {
    margin-right: -.625rem;
  }
  .sidebar-nav {
    margin: {
      right: -$sidebar-padding-x;
      left: -$sidebar-padding-x;
    }
  }
}

.cz-sidebar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  border-right: $border-width solid $border-color;
  .cz-sidebar-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .close {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.5rem;
    padding: 1rem $sidebar-padding-x;
    border-bottom: $border-width solid $border-color;
    background-color: $gray-100;
    float: none;
  }
  .cz-sidebar-body {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: $scrollbar-width;
      background-color: transparent;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg;
      @include border-radius($scrollbar-width / 2);
    }
    & > .simplebar-track {
      display: block;
      background-color: transparent;
    }
  }
}
@include media-breakpoint-up(lg) {
  .sidebar-fixed-enabled {
    padding-left: $sidebar-max-width;
  }
}

@include media-breakpoint-down(md) {
  .cz-sidebar {
    position: fixed;
    top: 0;
    left: -$sidebar-max-width;
    height: 100%;
    transition: $sidebar-transition;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    z-index: $zindex-offcanvas;
    &.show {
      left: 0;
      box-shadow: $sidebar-box-shadow !important;
    }
  }
  .cz-sidebar-header {
    display: flex;
    align-items: center;
  }
  .cz-sidebar-body {
    position: absolute;
    left: 0;
    width: calc(100% - .375rem);
    height: calc(100% - #{$sidebar-header-height});
    padding: {
      right: $spacer * 1.5;
      left: $spacer * 1.5;
    }
    overflow-y: auto;

    .sidebar-nav {
      margin: {
        right: -($spacer * 1.5);
        left: -($spacer * 1.5);
      }
    }

    &::-webkit-scrollbar {
      width: $scrollbar-width;
      background-color: transparent;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-bg;
      @include border-radius($scrollbar-width / 2);
    }
    .simplebar-content {
      padding-right: $spacer * 1.5 !important;
    }
    & > .simplebar-track {
      display: block;
      background-color: transparent;
    }
    [data-simplebar] .simplebar-content > * {
      margin-right: -.375rem;
    }
  }
  .cz-sidebar-fixed {
    padding-top: 3.5rem !important;
    border-right: 0;
    .close { display: block; }
    .cz-sidebar-body {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

}
