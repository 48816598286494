//
// Masonry grid
// based on https://vestride.github.io/Shuffle/
// --------------------------------------------------


// Base styles

.cz-masonry-grid[data-columns] {
  margin: {
    right: -($grid-gutter-width / 2);
    left: -($grid-gutter-width / 2);
  }
  overflow: hidden;

  @include clearfix;

  .grid-item {
    width: 100%;
    float: left;
    padding: {
      right: $grid-gutter-width / 2;
      left: $grid-gutter-width / 2;
      bottom: $grid-gutter-width;
    }
  }
}

.cz-masonry-grid[data-columns='1'] .grid-item {
  width: 100%;
}

@include media-breakpoint-up(sm) {
  .cz-masonry-grid[data-columns='2'],
  .cz-masonry-grid[data-columns='3'],
  .cz-masonry-grid[data-columns='4'],
  .cz-masonry-grid[data-columns='5'],
  .cz-masonry-grid[data-columns='6'] {
    .grid-item { width: 50%; }
  }
}
@include media-breakpoint-up(md) {
  .cz-masonry-grid[data-columns='3'],
  .cz-masonry-grid[data-columns='4'],
  .cz-masonry-grid[data-columns='5'],
  .cz-masonry-grid[data-columns='6'] {
    .grid-item { width: percentage(1 / 3); }
  }
}
@include media-breakpoint-up(lg) {
  .cz-masonry-grid[data-columns='4'],
  .cz-masonry-grid[data-columns='5'],
  .cz-masonry-grid[data-columns='6'] {
    .grid-item { width: 25%; }
  }
}
@include media-breakpoint-up(xl) {
  .cz-masonry-grid[data-columns='5'] {
    .grid-item { width: 20%; }
  }
  .cz-masonry-grid[data-columns='6'] {
    .grid-item { width: percentage(1 / 6); }
  }
}
